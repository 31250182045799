<template>
	<div class="mhome">
		<div class="mhome-wrap mgoods-list" @scroll="handleScroll" v-infinite-scroll="handleLiveScroll">
			<el-empty v-if="!liveGoods.length>0" :description="$t('mobile.empty.nodata')"></el-empty>
			<router-link class="item" v-for="(item,index) in liveGoods" :key="index" :to="{name:'mGoodsInfo',params:{id:item.goodsId},query:{live:$route.params.id}}">
				<div class="item-pic"><img :src="item.defaultImage" @error="imageLoadErrorGoods" alt=""></div>
				<div class="item-main">
					<div class="name">{{item.goodsName}}</div>
					<div class="price">￥<span>{{item.price}}</span></div>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
	import { getLiveGoods } from '@/api/mobile/live';
	export default {
		data() {
			return {
				liveGoods: [],
				pageSize: 10,
				pageCurr: 1,
				pageTotal: 0,
				pageflag: true,
				isScroll: false
			}
		},
		mounted() {
			this.getLiveGoods();
		},
		methods: {
			imageLoadErrorGoods(e) {
				e.target.src = this.$imageErrorGoods;
			},
			getLiveGoods(){
				let loading = this.$loading();
				let params = {
					page_index: (this.pageCurr - 1) * this.pageSize,
					page_size: this.pageSize,
					live_id: this.$route.params.id,
					app_key: this.$store.getters.appKey
				}
				getLiveGoods(params).then(response => {
					loading.close();
					if (response.data.list.length > 0) {
						this.liveGoods = this.liveGoods.concat(response.data.list);
					} else {
						this.pageflag = false;
					}
					this.pageTotal = parseInt(response.data.total);
				}).catch(() => {
					loading.close();
				})
			},
			handleScroll($event) {
				// var scrollTop = $event.target.scrollTop;
			},
			handleLiveScroll() {
				if(this.isScroll) {
					this.pageCurr++;
					if (this.pageflag) {
						this.getLiveGoods();
					}
				} else {
					this.isScroll = true
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.mgoods-list {
		background-color: #FFFFFF;
		.item {
			float: left;
			width: 50%;
			.item-pic {
				width: 100%;
				height: 6rem;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.item-main {
				padding: 0.32rem;
				.name {
					color: #333333;
					font-size: 0.416rem;
					height: 1.088rem;
					line-height: 0.544rem;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
				.price {
					color: #f40;
					font-size: 0.352rem;
					height: 0.544rem;
					line-height: 0.544rem;
					span {
						font-size: 0.448rem;
					}
				}
			}
		}
	}
</style>
